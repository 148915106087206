@import '__importable.scss';
.container {
	margin-top: 4rem;
}

.heading {
	border-bottom: 1px solid $gray-4;
	padding-bottom: 1.6rem;
	margin-bottom: 2.4rem;
}

.cardGrid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 0.8rem;

	@media screen and (max-width: $media-xxl) {
		overflow-x: scroll;
		scroll-snap-type: x mandatory;

		> div {
			scroll-snap-align: center;
		}

		&::-webkit-scrollbar {
			display: none;
		}
	}
}