@import '__importable.scss';
// TODO revise if possible for favorite icons
.images-container {
	display: grid;
	grid-template-areas: 'stack';
	justify-items: end;
	align-items: normal;

	> * {
		grid-area: stack;
		z-index: $zindex-2;
	}

	> div {
		width: 100%;
	}

	> [data-type*='BASE_FRAME'] {
		padding-top: 4.4rem;
	}

	> [type='button'] {
		margin-top: 1.6rem;
		margin-right: 1.6rem;
	}
}

.vto-button {
	width: 100% !important;
}

.stickyButton {
	display: flex;
	justify-content: space-between;
	bottom: 0.8rem;
	left: 0.8rem;
	position: fixed;
	background: $white;
	padding: 1.2rem;
	border-radius: 1.6rem;
	border: 1px solid $gray-2;
	width: calc(100% - 1.6rem);
	z-index: $zindex-3; // should sit above page content and footer, behind nav, drawers, build flow
	pointer-events: all;
	max-height: 7.7rem;

	@media screen and (min-width: $media-md-max) {
		max-width: 42rem;
		padding: 1.6rem;
		right: 2%;
		left: unset;
		max-height: 8.7rem;
	}

	.cta {
		min-width: 55%;
		@media screen and (min-width: $media-sm) {
			min-width: 21.1rem;
		}
	}

	.product-title {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		margin-bottom: 0;
	}
}

.stickyGradient {
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
	width: 100%;
	height: 6.4rem;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: $zindex-1;

	@media screen and (min-width: $media-sm) {
		display: none;
	}
}

.description {
	ul, li {
		list-style: disc;
		padding: revert;
	}
}

.termCopy {
	padding: 1.6rem 0;

	svg {
		max-width: fit-content;
	}
}

.subscriptionSection {
	margin-top: 1rem;
	margin-bottom: 2.4rem;
	gap: 1.4rem;
	.subscription-checklist {
		padding: 0;
		li:not(:last-child) {
			margin-bottom: 0.8rem;
		}
		gap: 0;
		[class*="Caption"]{
			padding: 0.8rem;
		}
	}

}

.tag {
	position: absolute;
	top: -0.6rem;
}